import React, { useEffect, useState } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Breadcrumb} from "gatsby-plugin-breadcrumb";

const TitresDiffuses = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    fetch(`${process.env.API_URL}/jsonapi/node/titre_joue?page[limit]=48&sort=-field_heure`, {
      headers: new Headers({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json',
        'Authorization': 'Basic `${process.env.API_AUTHORIZATION}`'
      }),
    })
    .then(response => response.json())
    .then(resultData => {
      resultData.data.map((track) => {
        let date  = new Date(track?.attributes.field_heure);
        let day = ('0' + (date.getDate())).slice(-2);
        let month = ('0' + (date.getMonth()+1)).slice(-2);

        track.attributes.field_heure = (
          <>
            <div className="track-date">{day}/{month}</div>
            <div className="track-hour">{date.getHours()}:{(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}</div>
          </>
        )
      })
      setTracks(resultData.data);
    })
  }, []);

  return (
    <Layout>
      <SEO title="Derniers titres joués" />
      <div className="container list-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel="Titres diffusés"
          />

          <h1 className="title is-1">Titres diffusés</h1>
        </section>

        <section className="section page-content">
          <div id="tracks-list"  className="has-background-primary">
            <div className="columns is-multiline tracks">
              {
                tracks.map((track , index) => (
                  <article className="column is-4 track" key={index}>
                    <div className="track-wrapper">
                      <div className="columns is-mobile is-vcentered is-variable is-4">
                        <div className="column">
                          <div className="track-full-date">
                            {track?.attributes.field_heure}
                          </div>
                        </div>
                        <div className="column">
                          <div className="track-title">{track?.attributes.title}</div>
                          <div className="track-artist">{track?.attributes.field_artiste}</div>
                        </div>
                      </div>
                    </div>
                  </article>
                ))
              }
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default TitresDiffuses
